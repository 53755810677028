var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filteredItems.length > 0 && _vm.canView)?_c('b-table',{attrs:{"hover":"","items":_vm.filteredItems,"fields":_vm.fieldsList,"responsive":"","sticky-header":"58vh","tbody-tr-class":_vm.rowClass,"sort-icon-left":"","foot":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center ml-25 mt-25"},[[_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.canView ? { name: _vm.itemTypeSingle, params: { slug: item.slug } } : null}},[_c('div',[_c('div',{staticClass:"mr-2\n                  w-100\n                  h-100\n                  d-flex\n                  align-items-center\n                  justify-content-center\n                  overflow-x-hidden\n                  "},[_c('safe-img',{staticClass:"thumbnail",attrs:{"src":_vm.getImageSrc(item.logoURL) || _vm.getImageSrc(item.bgURL) || _vm.getImageSrc(_vm.projectPlaceholder),"placeholder":_vm.projectPlaceholder,"alt":_vm.translate(item.name),"retry":5000,"height":"28","width":"40"}})],1)]),_c('div',[_c('h5',{staticClass:"font-weight-extrabold mb-0"},[_vm._v(" "+_vm._s(_vm.translate (item.name))+" "),(!item.isPublished)?_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(_vm.$t('projects.no-publish')))]):_vm._e()]),(item.headline)?_c('small',{staticClass:"html-text-ellipsis-2 text-secondary"},[_vm._v(" "+_vm._s(typeof item.headline === 'string' ? item.headline : Object.values(item.headline)[0])+" ")]):_vm._e()])])]],2)]}},{key:"cell(communityStatus)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 mt-50 align-items-center"},[(item.communityStatus)?_c('b-badge',{staticClass:"text-capitalize mb-0 check-button",staticStyle:{"min-width":"100px"},attrs:{"pill":"","variant":_vm.getColor(item.communityStatus.key)}},[_vm._v(" "+_vm._s(_vm.status(item.communityStatus.key))+" ")]):_c('b-badge',{staticClass:"text-capitalize mb-0 check-button",staticStyle:{"min-width":"100px"},attrs:{"pill":"","variant":"light-dark"}},[_vm._v(" --- ")])],1)]}},{key:"cell(startDate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 mt-50 ml-25 align-items-center"},[(item.startDate)?_c('p',{staticClass:" mb-0"},[_vm._v(" "+_vm._s(_vm.timestampToTime(item.startDate))+" ")]):_c('p',[_vm._v(" --- ")])])]}},{key:"cell(endDate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 mt-25 ml-50 align-items-center"},[(item.endDate)?_c('p',{staticClass:" mb-0"},[_vm._v(" "+_vm._s(_vm.timestampToTime(item.endDate))+" ")]):_c('p',[_vm._v(" --- ")])])]}}],null,false,2223884738)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
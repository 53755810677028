<template>
  <b-table
    v-if="filteredItems.length > 0 && canView"
    hover
    :items="filteredItems"
    :fields="fieldsList"
    responsive
    sticky-header="58vh"
    :tbody-tr-class="rowClass"
    sort-icon-left
    foot
  >
    <!-- Column: Name -->
    <template #cell(name)="{ item }">
      <b-media class="d-flex align-items-center ml-25 mt-25">
        <template>
          <b-link
            class="text-dark d-flex"
            :to="canView ? { name: itemTypeSingle, params: { slug: item.slug } } : null"
          >
            <div>
              <div
                class="mr-2
                    w-100
                    h-100
                    d-flex
                    align-items-center
                    justify-content-center
                    overflow-x-hidden
                    "
              >
                <safe-img
                  class="thumbnail"
                  :src="getImageSrc(item.logoURL) || getImageSrc(item.bgURL) || getImageSrc(projectPlaceholder)"
                  :placeholder="projectPlaceholder"
                  :alt="translate(item.name)"
                  :retry="5000"
                  height="28"
                  width="40"
                />
              </div>
            </div>
            <div>
              <h5 class="font-weight-extrabold mb-0">
                {{ translate (item.name) }} <span v-if="!item.isPublished" class="small text-danger">{{ $t('projects.no-publish') }}</span>
              </h5>
              <small
                v-if="item.headline"
                class="html-text-ellipsis-2 text-secondary"
              >
                {{ typeof item.headline === 'string' ? item.headline : Object.values(item.headline)[0] }}
              </small>
            </div>
          </b-link>
        </template>
      </b-media>
    </template>
    <!-- Column: Start Date -->
    <template #cell(communityStatus)="{ item }" style="max-width:120px">
      <div class="w-100 h-100 d-flex pl-1 mt-50 align-items-center">
        <b-badge
          v-if="item.communityStatus"
          pill
          :variant="getColor(item.communityStatus.key)"
          class="text-capitalize mb-0 check-button"
          style="min-width: 100px;"
        >
          {{ status(item.communityStatus.key) }}
        </b-badge>
        <b-badge
          v-else
          pill
          variant="light-dark"
          class="text-capitalize mb-0 check-button"
          style="min-width: 100px;"
        >
          ---
        </b-badge>
      </div>
    </template>
    <!-- Column: Start Date -->
    <template #cell(startDate)="{ item }" style="max-width:120px">
      <div class="w-100 h-100 d-flex pl-1 mt-50 ml-25 align-items-center">
        <p v-if="item.startDate" class=" mb-0">
          {{ timestampToTime(item.startDate) }}
        </p>
        <p v-else>
          ---
        </p>
      </div>
    </template>
    <!-- Column: End Date -->
    <template #cell(endDate)="{ item }" style="max-width:120px">
      <div class="w-100 h-100 d-flex pl-1 mt-25 ml-50 align-items-center">
        <p v-if="item.endDate" class=" mb-0">
          {{ timestampToTime(item.endDate) }}
        </p>
        <p v-else>
          ---
        </p>
      </div>
    </template>
    <!-- Column: likes -->
    <!-- <template #cell(totalLikes)="{ item }" style="max-width:120px">
      <div class="w-100 h-100 d-flex align-items-center pl-2">
        <p
          class="mb-0 text-muted mr-75 text-right"
        >
          {{ item.totalLikes || 0 }}
        </p>
        <feather-icon
          role="button"
          size="20"
          :class="
            item.likedByMember
              ? 'profile-likes'
              : 'profile-icon'
          "
          icon="HeartIcon"
          class="text-muted"
          @click.stop.prevent="toggleLike(item)"
        />
      </div>
    </template> -->
  </b-table>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ProjectPlaceholder from '@/assets/images/placeholders/light/projects.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import moment from 'moment';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'ProjectsTable',
  components: { SafeImg },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemTypeSingle: {
      type: String,
      default: 'project',
    },
    itemType: {
      type: String,
      default: 'projects',
    },
  },
  computed: {
    fieldsList() {
      return [
        {
          key: 'name',
          label: this.$t('backoffice.owner.name'),
          sortable: true,
        },
        {
          key: 'communityStatus',
          label: this.$t('backoffice.members.status'),
          sortable: true,
        },
        {
          key: 'startDate',
          label: this.$t('backoffice.discounts.start-date'),
          sortable: true,
        },
        {
          key: 'endDate',
          label: this.$t('backoffice.discounts.end-date'),
          sortable: true,
        },
        // {
        //   key: 'totalLikes',
        //   label: this.$t('social.like-plural'),
        // },
      ];
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canView() {
      return checkPermissions(
        'view',
        this.itemTypeSingle,
        this.loggedMemberRoles,
        this.collective,
      );
    },
    canAdd() {
      return checkPermissions('create', this.itemTypeSingle, this.loggedMemberRoles, this.collective);
    },
    filteredItems() {
      if (this.items && this.items.length > 0) {
        if (!this.canAdd) {
          return this.items.filter((item) => item.isPublished);
        }
        return this.items;
      }
      return [];
    },
    projectPlaceholder() {
      return ProjectPlaceholder;
    },
  },
  created() {

  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (!item.isPublished) return 'table-warning';
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    timestampToTime(timestamp) {
      moment.locale(this.$store.state.locale.currentLocale);
      return moment(timestamp).format('DD MMM YYYY - HH:mm');
    },
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'communitiesOthers',
        storedKey: this.itemType,
        morphType: this.itemTypeSingle,
        key: item.key,
      });
    },
    status(communityStatusCode) {
      switch (communityStatusCode) {
        case 'egPTvvKTSW':
          return this.$t('backoffice.feedback.status.to-do');
        case 'G4CdL1y09v':
          return this.$t('backoffice.feedback.status.in-progress');
        case 'Zj0k3D2HlD':
          return this.$t('backoffice.feedback.status.done');
        default:
          return this.$t('backoffice.feedback.status.without-status');
      }
    },
    getColor(status) {
      const colorStatus = {
        egPTvvKTSW: 'light-warning',
        G4CdL1y09v: 'light-success',
        Zj0k3D2HlD: 'light-danger',
      };
      return colorStatus[status];
    },
  },

};
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

</style>
